<template>

    <!--<q-btn
        :class="getComponentClass"
        :size="size"
        :color="color"
        :text-color="textColor"
        class="rounded-borders no-border q-ma-xs"
        @click.native="ModificaStato"
        > //-->

    <q-btn
        ref="QQCheckBox"
        :class="getComponentClass"
        :size="size"
        outline
        :disabled="disabled"
        color="color"
        class="rounded-borders no-border q-ma-xs"
        @click.native="ModificaStato"
        >

        <q-avatar
            class="posiziona_check"
            :icon="iconOn"
            size="50px"
            name="Icona_checked"
            v-show="is_checked"
            color="white"
            text-color="primary"
            style="border: 1px SOLID #000;"
        />

        <q-list class="full-width">

            <q-item v-show="icon != ''" class="no-margin no-padding justify-center">
                <q-icon :name="icon" style="font-size: 3.0em;" />
            </q-item>

            <q-item>
                <q-item-section class="no-margin no-padding">
                    <q-item-label class="text-center">{{ label }}</q-item-label>
                </q-item-section>

            <!--    <q-item-section avatar class="on-right">
                    <q-avatar
                        :icon="iconOn"
                        :size="size"
                        v-show="is_checked"
                    />
                </q-item-section> //-->

            </q-item>
        </q-list>

    </q-btn>

</template>

<script>
import commonLib from "@/libs/commonLib"

export default {
    name: "QQCheckBox",
    data() {
        return {
            color: ""
        }
    },
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false
        },
        size: {
            type: String,
            required: false,
            default: "md"
        },
        iconOn: {
            type: String,
            required: false,
            default: 'mdi-check-bold'
        },
        iconOff: {
            type: String,
            required: false,
            default: ''
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /*color: {
            type: String,
            required: false,
            //default: "blue-grey"
        },
        icon_color: {
            type: String,
            required: false,
            //default: "black"
        },
        textColor: {
            type: String,
            required: false,
            //default: "white"
        },*/
        shadow: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        ModificaStato() {
            if (commonLib.isUndefined(this.value)) {
                this.$emit('input',true);
            } else {
                this.$emit('input', !this.value);
            }
            this.$emit("btn-clicked");
        }
    },

    computed: {
        is_checked() {
            return this.value;
        },
        getComponentClass() {
            let classValue = "btn-fixed-width-"+this.size+" ";

            if (this.shadow) {
                classValue += "shadow-19 ";
            }

            return classValue;
        }
    }
}
</script>

<style scoped>
    .btn-fixed-width-sm {
        width: 140px;
        min-height: 50px;
    }
    .btn-fixed-width-md {
        width: 210px;
        min-height: 70px;
    }
    .btn-fixed-width-xl {
        width: 320px;
        min-height: 140px;
    }
    .posiziona_check {
        position: absolute;
        left: -16px;
        top: -16px;
    }
</style>
