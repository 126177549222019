<template>
    <div class="ElencoProposte q-pa-md">

        <div class="row justify-center">

            <div class="col-md-7 col-12" align="center">
                <br><br>
                <span class="text-h4">Proposta selezionata</span>
                <hr>
            </div>
        </div>

        <div class="row justify-center">

            <div class="col-md-6 col-12 " align="center">

                <q-card bordered class="my-card shadow-12 shadow-transition">
                    <q-card-section  class="bg-primary text-white">
                        <div class="text-h5 q-px-md" align="left">
                            <q-icon name="description" />
                            {{ proposta.compagnia }}
                        </div>
                    </q-card-section>

                    <q-card-section class="q-pt-none text-left" style="padding-left: 24px; padding-right: 24px;">
                        <strong style="font-size: 16px; color: #A0A0A0;">{{ proposta.label }}</strong>
                        <br>
                        <div align="left" class="q-pb-md q-pt-md">
                            <span class="text-h3" name="premio_annuale">{{ premio_da_mostrare }}</span><br>
                        </div>
                    </q-card-section>

                    <q-separator inset />

                    <q-card-section>
                        <q-list>
                            <template
                                v-for="(parametro, index) in proposta.parametri"
                                v-bind="parametro"
                            >

                                <template v-if="parametro.type == 'text'">
                                    <q-item v-ripple :disable="!parametro.is_enabled" style="padding: 0px;"
                                        v-bind:key="40+index">
                                        <q-item-section>
                                            <q-input :disable="!parametro.is_enabled"
                                                :filled="!parametro.is_enabled"
                                                outlined
                                                @input="onDomandaChange()"
                                                v-model="parametro.value"
                                                :label="parametro.label" >
                                                <template v-slot:prepend>
                                                    &euro;
                                                </template>
                                            </q-input>
                                        </q-item-section>
                                    </q-item>
                                </template>

                                <template v-if="parametro.type == 'select'">
                                    <q-item
                                        v-ripple
                                        :disable="!parametro.is_enabled"
                                        style="padding: 0px;"
                                        v-bind:key="40+index"
                                    >
                                        <q-item-section>
                                            <q-select :disable="!parametro.is_enabled"
                                                :filled="!parametro.is_enabled"
                                                outlined
                                                emit-value
                                                map-options
                                                @input="onDomandaChange($event)"
                                                v-model="parametro.value"
                                                :options="parametro.options"
                                                :label="parametro.label"
                                            />
                                        </q-item-section>
                                    </q-item>
                                </template>

                                <template v-if="parametro.type=='checkbox'">
                                    <q-item v-ripple  :disable="!parametro.is_enabled" style="padding: 0px; margin: 0px;" tag="label"
                                        v-bind:key="40+index">

                                        <q-item-section side top>
                                            <q-checkbox v-model="parametro.is_checked"
                                                :disable="!parametro.is_enabled"
                                                :filled="!parametro.is_enabled"
                                                @input="onDomandaChange()"
                                            />
                                        </q-item-section>

                                        <q-item-section class="text-left">
                                            <q-item-label v-text="parametro.label" />
                                            <q-item-label caption v-text="parametro.description" />
                                        </q-item-section>

                                    </q-item>
                                </template>

                            </template>
                        <br>
                        </q-list>
                    </q-card-section>

                    <q-card-actions align="right">

                        <QQCheckBox label="Seleziona"
                            size="sm"
                            ref="MioCheckBox"
                            name="Seleziona"
                            v-model="proposta.is_checked"
                            @btn-clicked="onBtnClicked"
                        />

                    </q-card-actions>
                </q-card>
            </div>
        </div>

        <!--  v-show="ha_consulenza" -->
        <fieldset>
                <legend><h5>Consulenza</h5></legend>

                <div class="justifi-center row">
                    <div class="col-12 col-md-12">
                        <table border="0" width="100%">
                            <tbody>
                                <tr>
                                    <td width="60%" align="center">
                                        <q-slider
                                            v-show="ha_consulenza"
                                            color="red"
                                            thumb-size="50px"
                                            track-size="15px"
                                            label-always
                                            :label-value="consulenza + '€'"
                                            v-model="consulenza"
                                            :min="importo_consulenza_minima"
                                            :max="importo_consulenza_massima"
                                            :inner-min="importo_consulenza_minima"
                                            :inner-max="importo_consulenza_massima"
                                            @change="onCambiaValoreConsulenza" />

                                        <div v-show="!ha_consulenza">
                                            <h5>Prodotto senza consulenza</h5>
                                        </div>
                                    </td>
                                    <td align="right" NoWrap  colspan="2">
                                        <h5>Costo di acquisto garantito</h5>
                                        <h2 class="q-mb-sm"><strong id="premio_totale_consulenza">{{ premio_totale }}</strong> Euro</h2>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <q-expansion-item
                                            v-show="isSede"
                                            v-model="altri_dati_visibili"
                                            expand-separator
                                            icon="mdi-eye-plus"
                                            name="visualizza_altre_proposte"
                                            label="Ulteriori informazioni"
                                            caption="Clicca QUI per visualizzarle"
                                            style="font-size: 18px; border: 1px SOLID #000;"
                                            color="primary"
                                            track-size="40px"
                                            label-lines>
                                            <br>
                                            <q-list bordered separator>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Premio proposta selezionata</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ premio_contratto }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Importo Diritti</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ importo_diritti_aby.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Consulenza minima selezionabile</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ importo_consulenza_minima.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Consulenza massima selezionabile</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ importo_consulenza_massima.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Consulenza impostata</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ consulenza.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Provvigioni di Compagnia</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ proposta.provvigioni.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Quota consulenza Unit&agrave; Operativa</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{importo_provvigioni_consulenza_collaboratore.toFixed(2)}} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Quota consulenza Aby</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{importo_provvigioni_consulenza_aby}} Euro</q-item-section>
                                                </q-item>
                                            </q-list>


                                        </q-expansion-item>
                                    </td>
                                    <td align="right"><h6>Provvigioni:</h6> </td>
                                    <td align="right"><h5><strong>{{ provvigioni_totali }}</strong> Euro</h5></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </fieldset>

        <hr>
        <div class="row justify-center">
            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-md-4" align="center">
                <QQButton label="ANNULLA RINNOVO" color="blue-grey" icon="undo" size="md"
                    @click.native="onAnnullaRinnovo"
                />
            </div>
            <div class="col-md-4" align="center">
                <QQButton label="AVANTI"
                    color="blue-grey"
                    icon="mdi-arrow-right-bold"
                    size="md"
                    icon_align="right"

                    @click.native="onAvantiClicked"
                />
                <br><br><br><br><br>
            </div>
        </div>


    </div>
</template>

<script>
    import QQButton from "@/components/QQButton";
    import QQCheckBox from "@/components/QQCheckBox";
    import commonLib from "@/libs/commonLib";
    import policy from "@/libs/policy";
    import { mapState, mapActions } from "vuex";
    import { mapFields } from "vuex-map-fields";

    export default {
        name: "Proposta",
        data() {
            return {
                consulenza: 0,
                altri_dati_visibili: false,
                proposta_modificata: false,
            }
        },
        components: {
            QQCheckBox,
            //QQProposta,
            QQButton
        },
        methods: {
            ...mapActions({
                fetchConvertiInTaylormade: "gestioneRinnovi/fetchConvertiInTaylormade",
                aggiornaFormPreventivo: "formPreventivo/aggiornaFormPreventivo"
            }),
            onPropostaIsCheckedUpdate(proposta,dati) {
                console.log(proposta,dati);
            },
            onAnnullaRinnovo() {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No, neanche un po`",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, sono sicuro"
                    },
                    title: 'Attenzione',
                    message: 'Sei sicuro di voler annullare il rinnovo di questo preventivo?',
                }).onOk(async () => {
                    this.$router.push({name : "Preventivi"});
                });
            },
            async onDomandaChange() {
                // Trasforma il tutto in una taylormade
                await this.fetchConvertiInTaylormade(this.formPreventivo);
                this.consulenza = 0;

            },
            onIndietroClicked() {
                this.$router.push({name : "Rinnovi.AnalisiRischio"});
            },
            async onAvantiClicked() {
                console.log("formpreventivo",this.formPreventivo);
                // Registra la modifica del formPreventivo sul DB
                await this.aggiornaFormPreventivo({
                    guidPreventivo: this.formPreventivo.preventivo.id,
                    formPreventivo: this.formPreventivo
                });

                this.step = 6;
                    this.$router.push({name : "Preventivi.FormPreventivo"});
            },
            onCambiaValoreConsulenza() {

            },
            onBtnClicked() {

            }
        },
        computed: {
            ...mapState({
                formPreventivo: state => state.formPreventivo
            }),
            ...mapFields('formPreventivo', [
                "step"
            ]),
            isSede() {
                return policy.isSede();
            },
            proposta() {
                if (commonLib.isEmpty(this.formPreventivo)) return {};

                var prodotto = this.formPreventivo.preventivo.prodotti.find(p => {
                    return p.is_checked;
                });
                var proposta = prodotto.proposte.find(p => {
                    return p.is_checked;
                });

                return proposta;
            },
            prodotto() {
                if (commonLib.isEmpty(this.formPreventivo)) return {};

                var prodotto = this.formPreventivo.preventivo.prodotti.find(p => {
                    return p.is_checked;
                });

                return prodotto;
            },
            importo_provvigioni_consulenza_collaboratore() {
                var aliquota_consulenza_collaboratore = this.proposta.aliquota_consulenza_collaboratore;
                var importo = this.consulenza * aliquota_consulenza_collaboratore / 100;

                console.log("aliquota_consulenza_collaboratore:",aliquota_consulenza_collaboratore,importo);

                return importo;
            },
            importo_provvigioni_consulenza_aby()  {
                var importo = this.consulenza - this.importo_provvigioni_consulenza_collaboratore;

                return importo.toFixed(2);
            },
            importo_diritti_aby() {
                return parseFloat(this.proposta.diritti_aby);
            },
            provvigioni_totali() {
                return (parseFloat(this.importo_provvigioni_consulenza_collaboratore) +
                        parseFloat(this.proposta.provvigioni)).toFixed(2);
            },
            premio_contratto() {
                return this.proposta.premio.toFixed(2);
            },
            premio_da_mostrare() {
                var proposta = this.proposta;
                return "€ "+proposta.premio.toFixed(2);
            },
            importo_consulenza_minima() {
                return this.prodotto.importo_consulenza_min;
            },
            importo_consulenza_massima() {
                return this.prodotto.importo_consulenza_max;
            },
            ha_consulenza() {
                return (this.importo_consulenza_massima > 0);
            },
            premio_totale() {
                return parseFloat(this.proposta.premio +
                                  this.proposta.diritti_aby +
                                  this.consulenza).toFixed(2);
            },
        },
        async mounted() {
            this.consulenza = this.prodotto.consulenza;
        }
    }

</script>
